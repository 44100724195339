<template>
  <!-- ======= Header ======= -->
  <header id="header" class="d-flex align-items-center">
    <div class="container d-flex align-items-center">

      <div class="logo me-auto">
         <a href="/"><img width="70px" src="https://zarshan.ir/wp-content/uploads/2020/06/pc-logo3.png" alt="" class="img-fluid"></a>
      </div>

      <nav id="navbar" class="navbar">
        <ul>

          <li v-for="item in items" :key="item.name" :class="item.hasOwnProperty('prop') ? 'dropdown' : ''">

            <a v-if="item.hasOwnProperty('prop')" @click.prevent><span>{{ item.name }}</span><i class="bi bi-chevron-down"></i></a>
            <a class="nav-link" v-else :href="item.url">{{ item.name }}</a>

            <ul v-if="item.hasOwnProperty('prop')">

              <li v-for="subitem in item.prop" :key="subitem.name" :class="subitem.hasOwnProperty('prop') ? 'dropdown' : ''">

                <a v-if="subitem.hasOwnProperty('prop')" @click.prevent><span>{{ subitem.name }}</span><i class="bi bi-chevron-down"></i></a>
                <a class="nav-link" v-else :href="subitem.url">{{ subitem.name }}</a>

                <ul v-if="subitem.hasOwnProperty('prop')">

                  <li v-for="subsubitem in subitem.prop" :key="subsubitem.name">
                    <a :href="subsubitem.url">{{ subsubitem.name }}</a>
                  </li>

                </ul>

              </li>

            </ul>

          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    items: [
      {
        name: "Home",
        url: "/"
      },
      {
        name: "Buy saffron",
        url: "/buy-saffron"
      },
      {
        name: "Account",
        url: "/account"
      },
      {
        name: "Cart",
        url: "/cart"
      },
      {
        name: "Blog",
        prop: [
          {
            name: "Saffron types",
            prop: [
              {
                name: "Types of for-expert saffron",
                url: "/post"
              }
            ]
          },
          {
            name: "Exporting saffron",
            url: "/post"
          },
          {
            name: "Saffron coast",
            prop: [
              {
                name: "Saffron coast in Europe",
                url: "/post"
              },
              {
                name: "Saffron coast in Oman",
                url: "/post"
              },
              {
                name: "Saffron coast in China",
                url: "/post"
              },
              {
                name: "Saffron coast in Dubai",
                url: "/post"
              }
            ]
          },
          {
            name: "Buying saffron",
            prop: [
              {
                name: "Buying major and bulk saffron",
                url: "/post",
              },
              {
                name: "Buying for-expert saffron",
                url: "/post"
              }
            ]
          },
          {
            name: "Confidants of saffron",
            prop: [
              {
                name: "Effect of saffron on Coronavirus",
                url: "/post"
              },
              {
                name: "Weight loss and slimming with saffron",
                url: "/post"
              },
              {
                name: "Effect of saffron on liver",
                url: "/post"
              },
              {
                name: "Effect of saffron on heart",
                url: "/post"
              }
            ]
          },
        ]
      },
      {
        name: "Persian",
        url: "/fa"
      }
    ]
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  background: transparent!important;
  box-shadow: unset!important;
  position: absolute!important;
}
#navbar > ul > li > a {
  color: white!important;
}
</style>
