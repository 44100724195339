<template>
  <!-- ======= About Us Section ======= -->
  <section id="types" class="types">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Saffron Types</h2>
      </div>
      <br>

      <div class="row no-gutters" v-for="type in types" :key="type.id">

        <div class="col-lg-6 image d-none d-lg-block" v-if="type.place === 'right'">
          <img alt="" class="img-fluid" :src="type.image">
        </div>

        <div class="col-lg-6 d-flex flex-column justify-content-center about-content">

          <div class="section-title">
            <h2>{{ type.name }}</h2>
            <div class="col-lg-6 image d-lg-none">
              <img alt="" class="img-fluid" :src="type.image">
            </div>
            <p>
              {{ type.paragraph }}
            </p>
          </div>

        </div>

        <div class="col-lg-6 image d-none d-lg-block" v-if="type.place === 'left'">
          <img alt="" class="img-fluid" :src="type.image">
        </div>

      </div>

    </div>
  </section><!-- End About Us Section -->
</template>

<script>
export default {
  name: "SaffronTypes",
  data: () => ({
    types: [
      {
        id: 1,
        image: "assets/img/types/SuperNegin.png",
        name: "SUPER NEGIN",
        paragraph: "This type of saffron has reputation for higher quality among the others. it just includes the red strings of saffron and no roots. it’s the most common saffron to export.",
        place: "right"
      },
      {
        id: 2,
        image: "assets/img/types/Negin.png",
        name: "NEGIN",
        paragraph: "This type of saffron has some commons with Super Negin saffron. both of them include the red strings and no roots. But the strings of Negin is smaller than super negin and its quality is lower than it.",
        place: "left"
      },
      {
        id: 3,
        image: "assets/img/types/WholeFilament.png",
        name: "WHOLE FILAMENT",
        paragraph: "This type of saffron is not common in the world. but it’s the purest saffron on saffron market. It’s so good for medical using and It’s cheaper than Negin and Super Negin saffron because the process of changing whole filament to Negin is a costly process.",
        place: "right"
      },
      {
        id: 4,
        image: "assets/img/types/SaffronRoot.png",
        name: "SAFFRON ROOT",
        paragraph: "Saffron root includes white and yellow segment of saffron. and it’s the cheapest segment of saffron but it can be so useful and economical for making saffron tea and herbal tea.",
        place: "left"
      }
    ]
  })
}
</script>

<style scoped>

</style>