<template>
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Contact Us</h2>
      </div>

      <div class="row">

        <div class="col-lg-6 d-flex" data-aos="fade-up">
          <div class="info-box">
            <i class="bx bx-map"></i>
            <h3>Our Address</h3>
            <p>No79, Ghasemi St, Habibollah St, Azadi St, Tehran,</p>
          </div>
        </div>

        <div class="col-lg-3 d-flex" data-aos="fade-up" data-aos-delay="100">
          <div class="info-box">
            <i class="bx bx-envelope"></i>
            <h3>Email Us</h3>
            <p><a href="mailto:Zarshanir@gmail.com" style="text-decoration: none; color: #444">Zarshanir [at] gmail [dot] com</a></p>
          </div>
        </div>

        <div class="col-lg-3 d-flex" data-aos="fade-up" data-aos-delay="200">
          <div class="info-box ">
            <i class="bx bx-phone-call"></i>
            <h3>Call Us</h3>
            <p><a href="tel:+989129388235" style="text-decoration: none; color: #444">+98 912 938 8235</a></p>
          </div>
        </div>

        <div class="col-lg-12" data-aos="fade-up" data-aos-delay="300">
          <form method="post" role="form" class="php-email-form" :action="'mailto:zarshanir@gmail.com?subject=' + name + ' - ' + subject + '&body=' + body">
            <div class="row">
              <div class="col-lg-6 form-group">
                <input v-model="name" type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
              </div>
              <div class="col-lg-6 form-group">
                <input v-model='email' type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
              </div>
            </div>
            <div class="form-group">
              <input v-model="subject" type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
            </div>
            <div class="form-group">
              <textarea v-model='body' class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button type="submit">Send Message</button></div>
          </form>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
name: "Contact",
  data: ()=>({
    body: "",
    subject: "",
    email: "",
    name: ""
  })
}
</script>

<style scoped>

</style>