<template>
  <section id="hero">
    <div class="hero-container">
      <div id="heroCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

        <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

        <div class="carousel-inner" role="listbox">

          <div class="carousel-item" v-for="slide in slides" :class="{ active: slide.id === 1 }" :key="slide.id" :style="{ 'background-image': 'url( '+ slide.image +' )' }">
            <div class="carousel-container">
              <div class="carousel-content container">
                <h2 class="animate__animated animate__fadeInDown" style="font-size: 60px"><span>{{ slide.heading }}</span></h2>
                <p class="animate__animated animate__fadeInUp" v-if="slide.paragraph !== ''">{{ slide.paragraph }}</p>

                <a v-for="button in buttons" :key="button.id" :href="button.link" :style="{ 'background': button.color, 'margin-top': '4%'}"
                   class="btn-get-started animate__animated animate__fadeInUp" :class="{ 'scrollto': 'button.scroll' }">{{ button.text }}</a>

                  <ul class="benefits animate__animated animate__fadeInUp">
                    <li v-for="benefit in benefits" :key="benefit.id">
                      <i :class="benefit.icon"></i>
                      <span>{{ benefit.text }}</span>
                    </li>
                  </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slider",
  data: ()=>({
    benefits: [
      {
        id: 1,
        icon: "bi bi-cash",
        text: "Best price (due to no middle man)"
      },
      {
        id: 2,
        icon: "bi bi-graph-up",
        text: 'Best Quality (Guaranteed labratory test result)'
      },
    ],
    buttons: [
      {
        id: 1,
        text: 'About us',
        link: '#about',
        color: '#A52722',
        scroll: true
      },
      {
        id: 2,
        text: 'Buy Saffron',
        link: '/',
        color: '#474B50',
        scroll: false
      }
    ],
    slides: [
      {
        id: 1,
        image: 'assets/img/saffron/webp/0031.webp',
        heading: 'Buy saffron directly from Iranian farmers',
        paragraph: ''
      },
      {
        id: 2,
        image: 'assets/img/saffron/webp/6348.webp',
        heading: 'Iranian saffron farmers produce 90 percent of world\'s saffron',
        paragraph: ''
      },
      {
        id: 3,
        image: 'assets/img/saffron/webp/0096.webp',
        heading: 'Natural iranian saffron on zarshan',
        paragraph: ''
      },
    ]
  })
}
</script>

<style scoped>
/*.carousel-item.active:before {*/
/*  background-color: unset!important;*/
/*}*/
/*.carousel-item {*/
/*  background-size: contain!important;*/
/*}*/

#hero {
  margin-top: 0 !important;
  height: 100vh!important;
}
#heroCarousel > div > div {
  height: 100vh;
}
#heroCarousel > div > div > div > div, #heroCarousel > div > div > div > div > p {
  text-align: center!important;
}
#hero p {
  margin: auto!important;
  vertical-align: middle;
}
</style>