<template>
  <section id="services" class="services">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Our Services</h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 icon-box" v-for="service in services"
             :key="service.id" data-aos="fade-up" :data-aos-delay="service.delay">
          <div class="icon"><img class="svg-ic" :alt="service.icon" :src="'/assets/img/icons/' + service.icon + '.svg'"></div>
          <h4 class="title"><a href="">{{ service.title }}</a></h4>
          <p class="description">{{ service.text }}</p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
name: "Services",
  data: ()=>({
    services: [
      {
        id: 1,
        title: "CIF Delivery",
        text: "CIF Delivery to All the World",
        icon: "delivery",
        delay: 0
      },
      {
        id: 2,
        title: "Safe Payment",
        text: "Safe Payment Channel",
        icon: "shield",
        delay: 100
      },
      {
        id: 3,
        title: "Door to Door Delivery",
        text: "Door to Door Delivery for some European and Asian country",
        icon: "package",
        delay: 200
      },
      {
        id: 4,
        title: "Certificated Saffron",
        text: "providing all information about your orders (like:farmer' name, location of farmland, and quality analysis of farmer saffron)",
        icon: "detail",
        delay: 300
      },
      {
        id: 5,
        title: "Pure And Guaranteed Quality",
        text: "Testing the quality of saffron in labratory (Based on the standard of your origin like ISO 3632 and the other standards if you want",
        icon: "100-natural-badge",
        delay: 400
      },
      {
        id: 6,
        title: "High Supply Capability",
        text: "Due to the large number of our farmers, zarshan can supply more than 1000 kg saffron monthly.",
        icon: "inventory",
        delay: 500
      }
    ]
  })
}
</script>

<style scoped>
.svg-ic {
filter: invert(.5) sepia(1) saturate(185) hue-rotate(30deg); width: 62%
}
</style>