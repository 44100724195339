<template>
  <section id="portfolio" class="portfolio section-bg">
    <div class="container" data-aos="fade-up" data-aos-delay="100">

      <div class="section-title">
        <h2>Our Portfolio</h2>
        <p>Here is some picture from our amazing saffrons!</p>
      </div>

      <div class="row portfolio-container">

        <div class="col-lg-4 col-md-6 portfolio-item" v-for="image in images" :key="image">
          <div class="portfolio-wrap">
            <img :src="'assets/img/Portfolio/' + image" class="img-fluid" alt="">
          </div>
        </div>

      </div>

    </div>
  </section>

</template>

<script>
export default {
name: "OurPortfolio",
  data: ()=>({
    images: [
        // "1.JPG",
        "2.JPG",
        "3.JPG",
        "4.JPG",
        "5.JPG",
        "6.JPG",
        "7.JPG"
    ]
  })
}
</script>

<style scoped>

</style>