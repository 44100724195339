<template>
  <div id="app">
    <Header />
    <Slider />
    <Services />
    <Counter />
    <SaffronTypes />
    <OurPortfolio />
    <AboutUs />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Slider from "./components/Slider";
import Services from "./components/Services";
import Counter from "./components/Counter";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import SaffronTypes from "./components/SaffronTypes";
import OurPortfolio from "./components/OurPortfolio";

export default {
  name: 'App',
  components: {
    OurPortfolio,
    SaffronTypes,
    Contact,
    Footer,
    AboutUs,
    Counter,
    Services,
    Slider,
    Header,
  }
}
</script>

<style>
</style>
