<template>
  <section class="counts section-bg">
    <div class="container">

      <div class="row">

        <div class="col-lg-4 col-md-6 text-center" data-aos="fade-up">
          <div class="count-box">
            <i class="bi bi-simple-smile" style="color: #20b38e;"></i>
            <span data-purecounter-start="0" data-purecounter-end="50" data-purecounter-duration="3" class="purecounter"></span>
            <p>Our Farmers</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 text-center" data-aos="fade-up" data-aos-delay="200">
          <div class="count-box">
            <i class="bi bi-document-folder" style="color: #c042ff;"></i>
            <span data-purecounter-start="0" data-purecounter-end="1300" data-purecounter-duration="1" class="purecounter"></span>
            <p>Our Saffron Consumers</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 text-center" data-aos="fade-up" data-aos-delay="400">
          <div class="count-box">
            <i class="bi bi-live-support" style="color: #46d1ff;"></i>
            <span data-purecounter-start="0" data-purecounter-end="1000" data-purecounter-duration="1" class="purecounter"></span>
            <p>Our supply capability(in month)</p>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
name: "Counter"
}
</script>

<style scoped>
section.counts.section-bg > div > div > div > div > span:before {
  content: "+";
}
section.counts.section-bg > div > div > div:nth-child(3) > div > span:after {
  content: " kg";
}
</style>